import * as React from 'react';
import { PageProps } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { getSrc } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MdxLink } from 'gatsby-theme-i18n';
import { useTranslation } from 'react-i18next';
import { Disqus } from 'gatsby-plugin-disqus';
import getPagePath from '../../utils/getPagePath';
import { graphql2post, GraphqlPost, PageContextProps, SiteQueryProps } from '../../utils/graphql';
import DefaultLayout from '../layouts/default-layout';
import SharingBar from '../SharingBar';
import Author from './author';
import getThemeAndTags from './getThemeAndTags';
import PostTitle from './post-title';
import PostDate from './post-date';
import PostTOC from './post-toc';
import PostFooter from './post-footer';
import { getThemeColor, getThemePseudoClassColor } from './post-theme';
import MailSubscription from './mail-subscription';
import useActiveSection from './useActiveSection';
import NotTranslated from '../NotTranslated';

const components = {
  a: MdxLink,
};

type PostQueryProps = {
  site: SiteQueryProps;
  mdx: GraphqlPost;
};

type PostProps = PageProps<PostQueryProps, PageContextProps>;

const Post: React.VoidFunctionComponent<PostProps> = ({ data: { site, mdx }, pageContext }) => {
  const { t } = useTranslation('news');

  const post = React.useMemo(() => (mdx ? graphql2post(mdx) : undefined), [mdx]);

  const pagePath = React.useMemo(() => getPagePath(pageContext, post), [pageContext, post]);

  const hrefLangUrlPrefix = React.useMemo(
    () => (pageContext.hrefLang ? `/${pageContext.hrefLang}` : ''),
    [pageContext.hrefLang],
  );

  const disqusConfig = React.useMemo(
    () =>
      post && {
        url: `${site.siteMetadata.siteUrl}${hrefLangUrlPrefix}${pagePath}`,
        identifier: pagePath,
        title: post.title,
        language: pageContext.hrefLang,
      },
    [post, site.siteMetadata.siteUrl, pagePath, pageContext.hrefLang, hrefLangUrlPrefix],
  );

  const [contentSection, activeSection] = useActiveSection();

  if (post && disqusConfig) {
    const headTitle = t('suffixed-head-title', { title: post.title });
    const headImage = post.socialImage ?? post.image;

    const themeAndTags = getThemeAndTags(t, post.theme, post.tags);
    const themeColor = getThemeColor(post);
    const themePseudoClassColor = getThemePseudoClassColor(post);

    return (
      <DefaultLayout
        headTitle={headTitle}
        headDescription={post.abstract}
        headImageSource={headImage && getSrc(headImage)}
        headImageAlt={post.imageAlt}
        lang={pageContext.locale}
        preserveNavbarSpace={false}
        theme={{ class: themeColor, pseudoClass: themePseudoClassColor }}
      >
        <article itemScope itemType="https://schema.org/BlogPosting" id="post">
          <header className={`pt-18 ${themeColor}`}>
            <div className="container px-4 py-4 sm:px-8 sm:py-8 md:py-12 lg:py-18">
              <link itemProp="publisher" href={`${site.siteMetadata.siteUrl}#organization`} />
              <meta itemProp="url" content={`/${pageContext.locale}${pagePath}`} />
              <div className="text-center text-xl font-medium sm:text-2xl">
                {themeAndTags.join(' • ')}
              </div>
              <PostTitle>{post.title}</PostTitle>
              <div className="text-center text-sm font-medium leading-normal">
                <span>{t('publishedOn')} </span>
                <PostDate date={post.date} locale={pageContext.locale} />
                <span>, </span>
                {post.authors.map((author) => (
                  <React.Fragment key={author.fullName}>
                    <Author author={author} locale={post.locale} />{' '}
                  </React.Fragment>
                ))}
                <span>• </span>
                <span>{t('timeToRead', { count: post.duration ?? post.timeToRead })}</span>
              </div>
              {themeAndTags && <meta itemProp="keywords" content={themeAndTags.join(',')} />}
            </div>
          </header>
          <div className="container px-4 sm:px-8">
            {mdx.frontmatter.showTableOfContents !== false ? (
              <PostTOC
                tableOfContents={post.tableOfContents}
                activeSection={activeSection}
                siteMetadata={site.siteMetadata}
              />
            ) : null}
            <section
              itemProp="articleBody"
              className="prose mt-8 px-4 xs:px-4 sm:px-8 md:px-20 lg:pl-12 lg:pr-64 xl:pr-96"
              ref={contentSection}
            >
              <MDXProvider components={components}>
                <MDXRenderer>{post.body}</MDXRenderer>
              </MDXProvider>
            </section>
          </div>
          <div className="container px-4 text-black sm:px-8">
            <PostFooter {...{ post }} />
            <SharingBar
              className="my-8 block justify-center lg:hidden"
              siteUrl={site.siteMetadata.siteUrl}
              socialIcons={site.siteMetadata.social}
              t={t}
            />
          </div>
        </article>
        <div className="container px-4 sm:px-8">
          <MailSubscription locale={pageContext.locale} />
          <Disqus config={disqusConfig} />
        </div>
      </DefaultLayout>
    );
  }
  return <NotTranslated pageContext={pageContext} />;
};

export default Post;
