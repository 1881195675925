import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageContextProps } from '../utils/graphql';
import DefaultLayout from './layouts/default-layout';

type NotTranslatedProps = {
  subject?: string;
  pageContext: PageContextProps;
};

const NotTranslated: React.VoidFunctionComponent<NotTranslatedProps> = ({
  subject,
  pageContext,
}) => {
  const { t } = useTranslation('components');
  const notTranslated = t('notTranslated.sentence', {
    subject: subject || t('notTranslated.defaultSubject'),
  });

  return (
    <DefaultLayout
      headTitle={notTranslated}
      headDescription={notTranslated}
      lang={pageContext.locale}
      wrapInContainer
    >
      <h1 className="mb-20 text-5xl">{notTranslated}</h1>
    </DefaultLayout>
  );
};

export default NotTranslated;
